<template>
  <div class="noticefabu">
    <div class="notiefabu_center" v-if="topdata !== null">
      <header>{{ topdata.title }}</header>
      <main>
        <div class="m_fixtop">
          <div class="mf_left">
            <div class="member_flex" v-if="topdata.userAvage == null">
              <img src="../../../assets/components/userin.png" alt />
            </div>
            <div class="member_flex" v-else>
              <img :src="listdata.userDetail.userAvage" alt />
            </div>
            <p class="mf_p">
              <i></i>
              <span>{{ topdata.createUser }}</span>
            </p>
          </div>
          <div class="mf_right">
            <p class="mfp_up">{{ topdata.content }}</p>
            <div style="display: flex; width: 80%; flex-wrap: wrap">
              <div
                v-for="item in topdata.resourceUrl"
                :key="item"
                style="
                  font-size: 12px;
                  cursor: pointer;
                  color: #409eff;
                  margin: 2px;
                "
                @click="handleView(item)"
              >
                <div class="boxV" v-if="item.resouceName.includes('.mp4')">
                  <img
                    class="imgAA"
                    src="../../../assets/icon/bgVideo1.png"
                    alt=""
                  />
                  <el-tooltip :content="item.resouceName" placement="top">
                    <video
                      :src="item.resouceUrl"
                      width="100"
                      height="80"
                    ></video>
                  </el-tooltip>
                </div>
                <div
                  v-else-if="
                    item.resouceName.includes('.png') ||
                    item.resouceName.includes('.jpg') ||
                    item.resouceName.includes('.jpeg')
                  "
                >
                  <el-tooltip :content="item.resouceName" placement="top">
                    <img
                      :src="item.resouceUrl"
                      width="100"
                      height="80"
                      alt=""
                    />
                  </el-tooltip>
                </div>
                <div v-else-if="item.resouceName.includes('.pdf')" class="boxP">
                  <el-tooltip :content="item.resouceName" placement="top">
                    <span style="margin-left: 3px">{{ item.resouceName }}</span>
                  </el-tooltip>
                </div>
                <div
                  v-else-if="
                    item.resouceName.includes('.xls') ||
                    item.resouceName.includes('.xlsx')
                  "
                  class="boxX"
                >
                  <el-tooltip :content="item.resouceName" placement="top">
                    <span style="margin-left: 3px">{{ item.resouceName }}</span>
                  </el-tooltip>
                </div>
                <div
                  v-else-if="
                    item.resouceName.includes('.doc') ||
                    item.resouceName.includes('.docx')
                  "
                  class="boxD"
                >
                  <el-tooltip :content="item.resouceName" placement="top">
                    <span style="margin-left: 3px">{{ item.resouceName }}</span>
                  </el-tooltip>
                </div>
              </div>
            </div>

            <div class="mfp_down">
              <!-- <p>{{topdata.resourceUrl}}</p> -->
             <!-- <p class="zilaio" @click="downLoad(topdata.resourceUrl[0])" v-if="topdata.resourceUrl[0]">
                <i></i>
                <span>资源下载</span>
              </p> -->
              <p
                v-if="this.currentShow || this.showdle"
                class="shanchu"
                @click="deleteTalk(topdata.id)"
              >
                <i></i>
                <span>删除</span>
              </p>
              <!-- <p class="louceng">1楼</p> -->
              <p class="shijian">{{ topdata.createTime }}</p>
            </div>
          </div>
        </div>
        <!-- userDetail -->
        <div class="m_fixtop" v-for="item in listdata" :key="item.id">
          <div class="mf_left">
            <div class="member_flex" v-if="item.userDetail.userAvage == null">
              <img src="../../../assets/components/userin.png" alt />
            </div>
            <div class="member_flex" v-else>
              <img :src="item.userDetail.userAvage" alt />
            </div>
            <p class="mf_p">
              <i v-if="item.userDetail.authorFlag == 1"></i>
              <span>{{ item.userDetail.userName }}</span>
            </p>
          </div>
          <div class="mf_right">
            <p class="mfp_up">{{ item.content }}</p>
            <div class="mfp_down">
              <p v-if="item.show || currentShow" class="shanchu" @click="deleteReply(item.id)">
                <i></i>
                <span>删除</span>
              </p>
              <p class="louceng">
                <!-- {{(curpage-1)*pagesize+item.id}}楼 -->
              </p>
              <p class="shijian">{{ item.createTime }}</p>
            </div>
          </div>
        </div>
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="curpage"
          :page-size="pagesize"
          layout="total,prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </main>
      <div v-if="this.cutstatus == 2 || this.currentShow">
        <footer v-if="showone != 1">
          <p class="f_title">
            <i></i>
            <span>发布回复</span>
          </p>
          <!-- <div class="f_i_title">
          <el-input v-model="talkform.title" placeholder="请输入标题" size="mini"></el-input>
        </div>-->
          <div class="f_i_title">
            <!-- <myEditor @gethtml="htmltext"></myEditor> -->
            <el-input
              v-model="talkform.content"
              placeholder="请输入内容"
              size="mini"
            ></el-input>
          </div>
          <el-button @click="saveCircleTalk" size="mini" type="primary">
            发布
          </el-button>
        </footer>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" title="预览" width="85%">
      <iframe
        :src="'https://view.officeapps.live.com/op/view.aspx?src=' + currentUrl"
        frameborder="0"
        width="100%"
        height="600"
      ></iframe>
    </el-dialog>
  </div>
</template>
<script>
import myEditor from "./com/myeditor.vue";
export default {
  components: {
    myEditor,
  },
  props: {
    fabutitle: {
      type: String,
      default: null,
    },
    fabuid: {
      type: Number,
      default: null,
    },
    cutstatus: {
      type: String,
      default: null,
    },
    cutadmin: {
      type: String,
      default: null,
    },
    showone: {
      type: String,
      default: null,
    },
    currentShow: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      // 楼主数据
      topdata: null,
      //列表数据
      listdata: null,
      // 表单
      talkform: {
        content: null, //话题内容
        // title: null, //话题标题
      },
      //分页
      curpage: 1,
      pagesize: 5,
      total: null,
      dialogVisible: false,
      currentUrl: "",
      showdle: false,
      showappear: false,
    };
  },
  created() {
    console.log(
      this.fabuid,
      "created",
      this.fabutitle,
      this.$route.query.introId
    );
    this.queryCircleTalkReply(this.fabuid);
    this.queryTalkDetailById(this.fabuid);
  },
  methods: {
    //
    htmltext(val) {
      console.log(val, "富文本的html");
      this.talkform.content = val;
    },
    // 查询话题的详细信息
    async queryTalkDetailById(id) {
      let form = new FormData();
      form.append("talkId", id - 0);
      const { data, status } = await this.$Http.queryTalkDetailById(form);
      if (status == 200) {
        console.log(data, status, "查询话题的详细信息-我是楼主");
        this.topdata = data;
        if (
          sessionStorage.getItem("currentUserName") == this.topdata.createUser
        ) {
          this.showdle = true;
        } else {
          this.showdle = false;
        }
      }
    },
    // 查看圈子回复
    async queryCircleTalkReply(id) {
      const { data, status } = await this.$Http.queryCircleTalkReply({
        pageNo: this.curpage,
        pageSize: this.pagesize,
        talkId: id, //话题id
        // replyId: replyId, //回复内容的id，查询某一条回复内容下的回复内容
      });
      if (status == 200) {
        console.log(data, status, "查看圈子回复");
        if (data == null) {
          this.listdata = data;
          this.total = 0;
        } else {
          this.listdata = data.list;
          this.total = data.total;
        }
        this.listdata.forEach((element) => {
          if (
            sessionStorage.getItem("currentUserName") ==
            element.userDetail.userName
          ) {
            element.show = true;
          } else {
            element.show = false;
          }
        });
      }
    },
    // 删除话题
    async deleteTalk(id) {
      this.$confirm("是否确定删除给话题", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let form = new FormData();
          form.append("talkId", id);
          const { data, status } = await this.$Http.deleteTalk(form);
          if (status == 200) {
            console.log(data, status, "删除话题");
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.$emit("cuthtml", 0);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 删除回复
    async deleteReply(id) {
      this.$confirm("是否确定删除回复", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let form = new FormData();
          form.append("replyId", id);
          const { data, status } = await this.$Http.deleteReply(form);
          if (status == 200) {
            console.log(data, status, "删除话题");
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.queryCircleTalkReply(this.fabuid);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    //发布回复
    async saveCircleTalk() {
      console.log("oopF");
      //   console.log(data, status, "发布回复");
      const { data, status } = await this.$Http.saveTailkReply({
        circleId: this.$route.query.introId, //圈子id
        content: this.talkform.content, //回复内容
        replyId: 0, //回复内容的id，查询某一条回复内容下的回复内容,默认为0
        talkId: this.fabuid, //话题id
      });
      if (status == 200) {
        console.log(data, status, "发布回复");
        this.talkform.content = null;
        this.queryCircleTalkReply(this.fabuid);
      }
    },
    // 下载
    // async downLoad(url, id) {
    //   let form = new FormData();
    //   form.append("url", url[0]);
    //   await this.$Http.downLoad(form).then((res) => {
    //     console.log(res, "下载资源");
    //     if (res.status == 200) {
    //       this.collect(this.fabuid, id);
    //     }
    //   });
    // },

    async downLoad(item) {
      console.log(this.cutstatus, "下载", item);
      let pardel = { url: item.resouceUrl };
      if (this.cutadmin == 0) {
        if (this.cutstatus == 1 || this.cutstatus == 3 || this.cutstatus == 0) {
          console.log(this.cutstatus, "this.cutstatus");
          this.$message.error("请加入圈子后下载");
          return;
        }
      }
      this.$axios({
        method: "get",
        url: "./api/file/downLoad",
        params: pardel,
        responseType: "blob",
      }).then(async (res) => {
        console.log(res, "下载文件");
        if (!res) {
          this.$message.error("文件获取失败");
          return;
        } else {
          await this.$Http
            .collect({
              resourceId: item.resouceId, //下载资源的id
              serviceId: this.fabuid, //业务id
              type: 2, //业务类型 1-课程/资料 2-教研圈
            })
            .then((result) => {
              console.log(result, "下载统计");
            });
        }
        if (res.status == 200) {
          console.log("开始下载");
          let url = window.URL.createObjectURL(new Blob([res.data]));
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          let fileNames = item.resouceName;
          link.setAttribute("download", fileNames);
          document.body.appendChild(link);
          link.click();
        }
      });
    },

    //分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.curpage = val;
      this.queryCircleTalkReply(this.fabuid);
    },

    handleView(val) {
      if (
        val.resouceName.includes(".doc") ||
        val.resouceName.includes(".docx") ||
        val.resouceName.includes(".xls") ||
        val.resouceName.includes(".xlsx") ||
        val.resouceName.includes(".ppt") ||
        val.resouceName.includes(".pptx")
      ) {
        this.dialogVisible = true;
        this.currentUrl = val.resouceUrl;
      } else {
        window.open(val.resouceUrl);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.noticefabu {
  margin-top: 20px;
  .notiefabu_center {
    width: 1160px;
    margin: 0 auto;
    background-color: #fff;
    padding: 15px 20px;
    header {
      // background-color: #fff;
      // padding: 15px 20px 0;
      max-width: 1160px;
      //   width: 256px;
      //   height: 21px;
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #141516;
      //   line-height: 19px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    main {
      // background-color: #fff;
      // padding: 0px 20px ;
      .m_fixtop {
        // margin-top: 15px;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        border-bottom: 2px solid #d7dce4;
        .mf_left {
          display: inline-block;
          .member_flex {
            display: flex;
            justify-content: center;
          }
          .mf_p {
            i {
              display: inline-block;
              width: 16px;
              height: 16px;
              vertical-align: middle;
              background: url("../../../assets/icon/creater.png") no-repeat;
            }
            span {
              // display: inline-block;
              // width: 36px;
              // height: 16px;
              font-size: 12px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #141516;
              // line-height: 16px;
              vertical-align: middle;
            }
          }
        }
        .mf_right {
          width: 1000px;
          .mfp_up {
            min-height: 50px;
            max-height: 60px;
            // height: 16px;
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #141516;
            // line-height: 14px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
          }
          .mfp_down {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-top: 10px;
            // width: 67px;
            // height: 16px;
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #8d9199;
            // line-height: 14px;
            .zilaio {
              cursor: pointer;
              i {
                display: inline-block;
                width: 17px;
                height: 16px;
                background: url("../../../assets/icon/download.png") no-repeat;
                vertical-align: middle;
              }
              span {
                vertical-align: middle;
              }
            }
            .zilaio:hover {
              color: #409eff;
            }
            .shanchu {
              cursor: pointer;
              margin-left: 15px;
              i {
                display: inline-block;
                width: 17px;
                height: 18px;
                background: url("../../../assets/icon/del.png") no-repeat;
                vertical-align: middle;
              }
              span {
                vertical-align: middle;
              }
            }
            .shanchu :hover {
              color: #409eff;
            }
            .louceng {
              margin-left: 15px;
              margin-top: 2px;
              vertical-align: middle;
            }
            .shijian {
              margin-left: 15px;
              margin-top: 2px;
              vertical-align: middle;
            }
          }
        }
      }
      .el-pagination {
        margin-top: 30px;
        display: flex;
        justify-content: center;
      }
    }
    footer {
      margin-top: 25px;
      background-color: #fff;
      padding: 20px;
      .f_title {
        i {
          display: inline-block;
          width: 18px;
          height: 18px;
          vertical-align: middle;
          background: url("../../../assets/icon/replytalk.png") no-repeat;
        }
        span {
          display: inline-block;
          vertical-align: middle;
          margin-left: 10px;
          // width: 80px;
          // height: 21px;
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #141516;
          // line-height: 19px;
        }
      }
      .f_i_title {
        margin: 10px 0;
      }
      .el-button {
        margin-top: 10px;
      }
    }
  }
}

.boxV {
  position: relative;
  width: 100px;
  height: 80px;
  line-height: 90px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background: url("../../../assets/icon/bgVideo.png");
  z-index: 11;
}
.imgAA {
  position: absolute;
  top: 35%;
  left: 35%;
}
.boxP {
  width: 100px;
  height: 80px;
  line-height: 90px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background: url("../../../assets/icon/bgPDF.png");
}
.boxX {
  width: 100px;
  height: 80px;
  line-height: 90px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background: url("../../../assets/icon/bgXLS.png");
}
.boxD {
  width: 100px;
  height: 80px;
  line-height: 90px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background: url("../../../assets/icon/bgDOC.png");
}
</style>
